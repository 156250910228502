#main{
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
}

#bottomText{
  max-width: 400px;
  margin: 0 auto;
  font-size: 9px !important;
  line-height: 12px;
}

#mainBox {
  width: 95%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.active {
    max-width: 400px;
    border-radius: 10px;
    max-height: 90vh;
    min-height: 70vh;
    height: auto;
    background-color: inherit;

    @media (max-width: 360px) and (max-height: 600px) {
      max-height: unset;
      margin-top: 1rem;
      margin-bottom: 1rem;;
    }

    @media (max-width: 290px) and (max-height: 700px){
      max-height: unset;
    }

  }
}


header {
  text-align: center;
}

footer {
  text-align: center;
}

section {
  display: -webkit-flex;
  display: flex;
  margin: auto 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#loaderContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.featured-image {
  width: 100%;
  img {
    max-height: 44vh;
    @media (max-width: 480px){
      max-height: 47vh;
    }
    @media (max-width: 360px) and (max-height: 600px) {
      max-width: 130px;
    }
  }
}

.video-player{
  width: 100%;
  max-width: 650px;
  position: relative;
  height: auto;
  &.active{
    .play-btn{
      display: none;
    }
  }
}


@media screen and (min-height: 1300px) {
    .mainContent {
        margin-bottom: 1rem !important;
        margin-top: 4rem !important;
    }
}